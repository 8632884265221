<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box mt20px">
    <ayl-main title="基本信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <el-form :model="form" :rules="rules" ref="form" :inline="true" label-position="left" label-width="108px">
          <el-form-item label="角色名称" prop="name">
            <el-input v-model.trim="form.name"></el-input>
          </el-form-item>
          <el-form-item label="角色状态" prop="status">
            <el-select v-model="form.status" placeholder="请选择角色状态">
          			  <el-option label="使用中" value="使用中"></el-option>
          			  <el-option label="已禁用" value="已禁用"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权限选择" prop="permissionList">
				  <el-cascader
          			  :options="addRole"
					        :props="props"
          			  ref="tree"
          			  collapse-tags
                  v-model="permissionLists"
					        :value='permissionLists'
          			  @change="handleChange"
          			  clearable></el-cascader>
          </el-form-item>
        </el-form>
      </div>
    </ayl-main>
    <ayl-main title="其他信息(选填)" :isCollapse="false">
      <div slot="main" class="p24px">
        <el-form :model="form" :inline="true" label-position="left" label-width="108px">
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 12}" placeholder="请输入内容" v-model.trim="form.remark">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </ayl-main>
    <div class="tac" style="margin-top: 40px">
      <el-button type="primary" @click="submitForms()" :loading="submitLoading">确认</el-button>
      <el-button style="margin-left: 30px" @click="$router.push('/sanitation-system-management/role-manage/role-manage')">返回</el-button>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        nav: [
          {name: "修改信息", path: ""},
        ],
		    props: { multiple: true, expandTrigger: 'hover' },
	    	addRole: [],
		    permissionLists: [],
        form: {
          name: this.$route.query.roleName,
		      status: this.$route.query.status,
		      permissionList: this.$route.query.permissionList,
		      remark: this.$route.query.remark,
        },
        rules: {
          name:[
          	{required: true, message: '请输入角色名称', trigger: 'blur'},
            {min: 1, max: 32, required: true, message: '请正确输入1-32位的角色名称', trigger: 'blur'},
            {validator: this.$validator.isContainBlank},
          ],
          status:[
          	{required: true, message: '请选择角色状态', trigger: 'change'},
          ],
          permissionList: [
            { type: 'array', required: true, message: '请选择角色权限', trigger: 'change' }
          ],
        },
        fileList: [],
        submitLoading: false
      }
    },
    methods: {
      handleRemove(file, fileList) {
      },
      handlePreview(file) {
      },
      submitDrver(){

      },
      handleChange() {
        let parentCompany = []
        parentCompany = this.$refs.tree.getCheckedNodes(true)
        //console.log(parentCompany)
        let arr = []
        for(let i in parentCompany){
          arr.push(parentCompany[i].value)
        }
        this.form.permissionList = arr
      },

      async submitForm() {
        //console.log(this.permissionList)
           await this.$api.getEdit({
              roleInfo:{
                roleIdentity: this.$route.query.roleIdentity,
                roleName: this.form.name,
                status: this.form.status,
                permissionList: this.form.permissionList,
                remark: this.form.remark,
              }
            })
            await this.$refs.form.validate((valid) => {
              if (valid) {
                this.$notify({
                  title: '成功',
                  message: '修改成功',
                  type: 'success'
                })
              } else {
                return false;
              }
            });
            await this.$router.push('/sanitation-system-management/role-manage/role-manage')
        },
      submitForms() {
      this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            this.submitForm()
          } catch (e){}
          this.submitLoading = false
        })
      },     
    },
	async mounted(){
		this.addRole = await this.$api.permissionTree({})
		//console.log(JSON.stringify(this.$route.query.checkedList))
		this.permissionLists = this.$route.query.checkedList
	}
  }
</script>

<style lang='sass' scoped>
.clearBox
  /deep/ .el-cascader__tags .el-tag .el-icon-close
    display: none
  /deep/.el-button--primary 
    background-color: rgba(26,188,156,1)
    border-color: rgba(26,188,156,1)
</style>
